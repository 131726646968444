<template>
  <div class="home-warp">
    <div class="home-title">
      <mt-swipe :auto="4000">
        <mt-swipe-item>
          <div class="banner1">
            <div class="text1">科技触发 生活之美</div>
            <div class="mask1"></div>
          </div>
        </mt-swipe-item>
        <mt-swipe-item>
          <div class="banner2">
            <div class="text2">科技改变 居住环境</div>
            <div class="mask2"></div>
          </div>
        </mt-swipe-item>
        <mt-swipe-item>
          <div class="banner3">
            <div class="text2">科技赋能 社区服务</div>
          </div>
        </mt-swipe-item>
      </mt-swipe>
      <div class="title-sub">
        <div class="sub-left">
          <div>
            <img class="home-b-t" src="../../static/images/home-b-t.png" alt="" />
          </div>
          <div style="margin-top: 0.1rem;">
            <img class="home-b-a" src="../../static/images/home-b-a.png" alt="" />
          </div>
        </div>
        <div class="sub-right" @click="jumpToHome2">
          <img class="home-s-b" src="../../static/images/home-s-b.png" alt="" />
        </div>
      </div>
    </div>
    <div class="home-content">
      <div style="
          width: 1.12rem;
          height: 0.08rem;
          background: #ffab0a;
          border-radius: 0rem;
          margin-bottom: 0.47rem;
        "></div>
      <div style="
          font-size: 0.4rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 0.64rem;
        ">
        {{$t('m.home.pre_1')}}{{$t('m.home.pre_2')}}{{$t('m.home.pre_3')}}
        <!-- 艾尔伴家是一家90后留学海归创业企业，公司以“5G、物联网、云计算、大数据、智能硬件”为核心，赋能社区加速完成“新基建”发展的一家综合性解决方案平台企业。 -->
      </div>
      <div class="content-video">
        <video class="video-class" controls autoplay loop src="https://aierbanjia-official-web.oss-cn-chengdu.aliyuncs.com/20210429135316.mp4?versionId=CAEQSBiBgMD42P_zyBciIDAyYjBjZmFjNDY4NzQ1ZmQ4Y2U2YTAzOWZjN2QxZWQz"></video>
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="solution">
      <div class="solution-title">
        <div class="title-text">解 决 方 案</div>
        <div class="title-text-e">SOLUTION</div>
      </div>
      <div class="solution-introduce">
        <!-- 我们理解的智慧城市是一种“可持续发展的城市”其中融合了智慧城市、生态城市、安全城市等各种理念，旨在建设更人性化、更高效、更友好、更安全、更宜居的可持续发展的城市。 -->
        {{$t('m.home.solution')}}
      </div>
      <div class="scroll-box1">
        <v-touch @swipeleft="swiperleft" @swiperight="swiperright" class="wrapper">
          <div class="scroll-box2">
            <div class="blocks-box" :style="{transform: `translateX(${translateWidth}rem)`}">
              <div class="list-box" :class="{'activeBlock':currentLockIndex == index}" v-for="(item, index) in list" :key="index" @click="jumpPage(item.path)">
                <div class="list-num">{{ item.num }}</div>
                <div class="list-content-box">
                  <h2 style="font-size: 0.64rem;color: #27201B;font-weight: bold;">{{ item.title }}</h2>
                  <div class="h3">{{ item.subtitle }}</div>
                  <div class="p">{{ item.content }}</div>
                </div>
                <div class="jump-img">
                  <img v-if="currentLockIndex == index" class="shou-img" src="../../static/images/shou.png" alt="">
                  <img v-else class="shou-img" src="../../static/images/home-hs.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </v-touch>
        <div class="progress-bar-box">
          <div class="active-progress" ref="activeProgress"></div>
          <div class="progress-bar" v-for="(item, index) in progressBarLength" :key="index"></div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div class="partner">
      <div class="solution-title">
        <div class="title-text">合 作 伙 伴</div>
        <div class="title-text-e">PARTNERS</div>
      </div>
      <div class="solution-introduce">
        <!-- 提升社区的安防、智能化和服务，成为伴家的合作伙伴，将会获得够快够强大的资源支持，帮助伙伴以比较低的综合成本快速开拓市场，获得最大利益。合作方式有AI+智慧社区（智能应用）生态合作、政企ICT项目（系统集成）规模合作，长期运营。“互联网+智慧物业”（物联网平台）可持续战略合作等。 -->
        {{$t('m.home.partners_1')}}
        {{$t('m.home.partners_2')}}
      </div>
      <div class="logo-img-box">
        <img v-for="(item, index) in logImgList" :key="index" class="logo-img" :src="item.url" alt="" />
      </div>
    </div>
    <div class="synopsis">
      <div style="text-align:center;display:flex;justify-content: center;">
        <div style="margin-top: -0.1rem;">
          <img style="width:0.71rem;height:0.69rem;object-fit: cover;" src="../../static/images/home-h-t.png" alt="">
        </div>
        <div style="margin-top: -0.1rem;">
          <img style="width:1.28rem;height:0.44rem;object-fit: cover;vertical-align: text-top;margin-left: 0.05rem;margin-top: 0.1rem;" src="../../static/images/home-h-a.png" alt="">
        </div>
      </div>
      <div style="text-align:center">
        <div>
          <h3>
            <!-- 简介 -->
            {{$t('m.home.nav_1')}}
          </h3>
          <p @click="jumpToCompany">
            <!-- 公司介绍 -->
            {{$t('m.home.nav_1_sub_1')}}
          </p>
          <p @click="jumpToAwards">
            <!-- 荣誉奖项 -->
            {{$t('m.home.nav_1_sub_2')}}
          </p>
        </div>
        <div>
          <h3>
            <!-- 业务 -->
            {{$t('m.home.nav_2')}}
          </h3>
          <p @click="jumpToGovernment">
            <!-- 面向政企 -->
            {{$t('m.home.nav_2_sub_1')}}
          </p>
          <p @click="jumpToUser">
            <!-- 面向用户 -->
            {{$t('m.home.nav_2_sub_2')}}
          </p>
        </div>
        <div>
          <h3>
            <!-- 媒体 -->
            {{$t('m.home.nav_3')}}
          </h3>
          <p @click="jumpToMedia">
            <!-- 企业动态 -->
            {{$t('m.home.nav_3_sub_1')}}
          </p>
          <p @click="jumpToMedia">
            <!-- 行业动态 -->
            {{$t('m.home.nav_3_sub_2')}}
          </p>
          <p @click="jumpToMedia">
            <!-- 媒体报道 -->
            {{$t('m.home.nav_3_sub_3')}}
          </p>
        </div>
        <h3 @click="jumpToPartners">
          <!-- 合作伙伴 -->
          {{$t('m.home.nav_4')}}
        </h3>
        <h3 @click="jumpToContactUs">
          <!-- 加入我们 -->
          {{$t('m.home.nav_5')}}
        </h3>
      </div>
    </div>
    <div class="footer">
      <div class="footer-top">
        <div class="top-left">
          <div style="margin-bottom:0.36rem;">
            <img style="width:0.29rem;height:0.29rem;object-fit:cover;margin-right:0.1rem;" src="../../static/images/tell.png" alt="">
            <span style="font-size: 0.4rem;font-weight: 400;margin-right:0.28rem;">400-0428-468</span>
            <span style="font-size: 0.24rem;font-weight: bold;">7x24小时服务热线</span>
          </div>
          <div style="margin-bottom:0.36rem;">
            <img style="width:0.29rem;height:0.29rem;object-fit:cover;margin-right:0.1rem;" src="../../static/images/email.png" alt="">
            <span style="font-size: 0.32rem;font-weight: 400;margin-right:0.28rem;">电子邮箱：aebj@air-bridge.cn</span>
          </div>
          <div>
            <img style="width:0.29rem;height:0.29rem;object-fit:cover;" src="../../static/images/address.png" alt="">
            <span style="font-size: 0.27rem;font-weight: 400;">
              公司地址：重庆市两江新区数字经济产业园6幢18F</span>
          </div>
        </div>
        <div class="top-right">
          <img src="../../static/images/qrcode_r.png" style="width: 1.71rem;height: 1.71rem;object-fit: cover;margin-top:0.3rem;" alt="">
        </div>
      </div>
      <div class="footer-bottom">
        <div>重庆市艾尔伴家科技有限公司</div>
        <div>渝ICP备17001103号-3 Copyright ©2016 - 2021</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      progressBarLength: [0, 1, 2, 3, 4, 5],
      list: [
        {
          title: '智 慧 社 区',
          subtitle: 'INTELLIGENT COMMUNITY',
          path: '/details/community',
          intro: 'home.solution_1',
          content: '智慧社区是社区管理的一种新理念,是新形势下社会管理创新的一种新模式。',
          num: '01'
        },
        {
          title: '智慧消防',
          subtitle: 'INTELLIGENT FIRE',
          path: '/details/fire',
          intro: 'home.solution_2',
          content: '智慧消防解决方案通过对消防隐患的全态监控，结合应急联动保障，实现火灾隐患早发现，早消除。',
          num: '02'
        },
        {
          title: '智慧城管',
          subtitle: 'INTELLIGENT FIRE',
          path: '/details/city',
          intro: 'home.solution_3',
          content: '智慧城管是基于网格化管理，以地理信息系统为核心的，新一代信息技术支撑、社会知识创新环境下的城市管理新模式。',
          num: '03'
        },
        {
          title: '智慧园区',
          subtitle: 'INTELLIGENT FIRE',
          path: '/details/park',
          intro: 'home.solution_4',
          content: '智智慧园区数字化解决方案实现楼宇园区的数据融合，一体化管理以及智能应用。',
          num: '04'
        },
        {
          title: '智慧交通',
          subtitle: 'INTELLIGENT FIRE',
          path: '/details/traffic',
          intro: 'home.solution_5',
          content: '主要包括设备监测、交通态势、统计决策和诱导发布等模块，贯穿了交通数据。',
          num: '05'
        },
        {
          title: '智慧治理',
          subtitle: 'INTELLIGENT FIRE',
          path: '/details/society',
          intro: 'home.solution_6',
          content: '采用“9+X”框架，严格遵循《社会治安综合治理综治中心建设与管理规范。',
          num: '06'
        },
      ],
      logImgList: [
        { url: require('../../static/images/logo_hw.png') },
        { url: require('../../static/images/logo_zgdx.png') },
        { url: require('../../static/images/logo_aglt.png') },
        { url: require('../../static/images/logo_zgyd.png') },
        { url: require('../../static/images/logo_cx .png') },
        { url: require('../../static/images/logo_hua.png') },
        { url: require('../../static/images/logo_lcfw.png') },
        { url: require('../../static/images/logo_lcfw.png') },
        { url: require('../../static/images/ziguang.png') }
      ],
      translateWidth: 0,
      currentLockIndex: 0,
    }
  },
  created() {
    // console.log(this.$i18n.locale);
  },
  methods: {
    swiperleft() {
      if (this.currentLockIndex === this.list.length - 1) {
        return
      }
      this.currentLockIndex++
      // if (this.currentLockIndex === this.list.length - 1) {
      //   this.translateWidth = -5.85 * this.currentLockIndex 
      // } else 
      if (this.currentLockIndex === 1) {
        this.translateWidth = -3.6
      } else {
        this.translateWidth = -5.85 * (this.currentLockIndex - 1) - 3.6
      }
      this.currentLockImg = this.list[this.currentLockIndex].coverUrlName
      this.$refs.activeProgress.style.left = (this.currentLockIndex) * 0.7 + 'rem'
    },
    swiperright() {
      if (this.currentLockIndex === 0) {
        return
      }
      this.currentLockIndex--
      // if (this.currentLockIndex === this.list.length - 1) {
      //   this.translateWidth = -5.85 * this.currentLockIndex + 4.5
      // } else 
      if (this.currentLockIndex === 0) {
        this.translateWidth = 0
      } else {
        this.translateWidth = -5.85 * (this.currentLockIndex - 1) - 3.6
      }
      this.currentLockImg = this.list[this.currentLockIndex].coverUrlName
      this.$refs.activeProgress.style.left = (this.currentLockIndex) * 0.7 + 'rem'
    },
    jumpPage(path) {
      this.$router.push(path)
    },
    jumpToHome2() {
      this.$router.push('/home2')
    },
    jumpToCompany() {
      this.$router.push('/synopsis')
    },
    jumpToAwards() {
      this.$router.push('/awards')
    },
    jumpToGovernment() {
      this.$router.push('/faceGovernment')
    },
    jumpToUser() {
      this.$router.push('/faceUser')
    },
    jumpToMedia() {
      this.$router.push('/media')
    },
    jumpToPartners() {
      this.$router.push('/partners')
    },
    jumpToContactUs() {
      this.$router.push('/contactUs')
    }
  }
}
</script>

<style lang="scss" scoped>
.home-warp {
  background: #f9f9f9;
}
.home-title {
  width: 100%;
  height: 7.07rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  .title-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    outline: none;
  }
  .title-sub {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 0;
    width: 9.31rem;
    box-sizing: border-box;
    margin-top: 0.33rem;
    margin-right: 0.41rem;
    margin-left: 0.29rem;
    .sub-left {
      display: flex;
      .home-b-t {
        width: 0.75rem;
        height: 0.75rem;
        object-fit: cover;
      }
      .home-b-a {
        width: 1.47rem;
        height: 0.49rem;
        object-fit: cover;
        vertical-align: text-top;
        margin-left: 0.1rem;
      }
    }
    .sub-right {
      .home-s-b {
        width: 0.68rem;
        height: 0.57rem;
        object-fit: cover;
      }
    }
  }
}
.home-content {
  margin-top: 0.87rem;
  box-sizing: border-box;
  padding: 0 0.43rem;
  .content-video {
    margin-top: 1.13rem;
    margin-bottom: 1.49rem;
    .video-class {
      width: 100%;
      height: 5.15rem;
    }
  }
}
.solution {
  .solution-title {
    margin: auto;
    .title-text {
      font-size: 0.56rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #27201b;
      text-align: center;
    }
    .title-text-e {
      margin-top: -0.45rem;
      text-align: center;
      font-size: 0.56rem;
      font-family: DINCond-Bold;
      font-weight: 600;
      color: #27201b;
      opacity: 0.1;
    }
  }
  .solution-introduce {
    box-sizing: border-box;
    padding: 0.21rem 0.44rem 1.72rem 0.43rem;
    font-size: 0.32rem;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #999999;
    line-height: 0.48rem;
  }
}
.partner {
  // background: url('../../static/images/home-bg1.png');
  .solution-title {
    margin: auto;
    .title-text {
      font-size: 0.56rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #27201b;
      text-align: center;
    }
    .title-text-e {
      margin-top: -0.45rem;
      text-align: center;
      font-size: 0.56rem;
      font-family: DINCond-Bold;
      font-weight: 600;
      color: #27201b;
      opacity: 0.1;
    }
  }
  .solution-introduce {
    box-sizing: border-box;
    padding: 0.21rem 0.44rem 1.72rem 0.43rem;
    font-size: 0.32rem;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #999999;
    line-height: 0.48rem;
  }
  .logo-img-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 0.39rem;
    margin-right: 0.36rem;
    box-sizing: border-box;
    .logo-img {
      margin-bottom: 0.23rem;
      width: 2.91rem;
      height: 1.68rem;
      object-fit: cover;
    }
  }
}
.synopsis p {
  margin-top: 0.3rem;
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #666666;
  cursor: pointer;
}
.synopsis h3 {
  margin-top: 0.6rem;
  margin-bottom: 0.1rem;
  font-size: 0.45rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}
.footer {
  color: #fff;
  padding: 0.4rem 0.3rem 0.1rem 0.3rem;
  box-sizing: border-box;
  margin-top: 0.33rem;
  width: 100%;
  background: url("../../static/images/home-footer.png");
  background-size: 100% 100%;
}
.footer-top {
  display: flex;
  justify-content: space-between;
}
.footer-bottom {
  text-align: center;
  margin-top: 0.49rem;
  box-sizing: border-box;
}
.footer-bottom div {
  font-size: 0.24rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  opacity: 0.7;
}
.scroll-box2 {
  overflow: hidden;
  padding-left: 0.38rem;
  box-sizing: border-box;
}
.list-box {
  position: relative;
  margin-right: 1rem;
  padding: 0.3rem 0.28rem 0.14rem 0.28rem;
  width: 5rem;
  height: 6.8rem;
  background: url("../../static/images/home-hb.png");
  background-size: 100% 100%;
}
.list-num {
  margin-left: 0.2rem;
  font-size: 0.9rem;
  font-family: DIN;
  font-weight: bold;
  color: #999999;
  line-height: 0.75rem;
  opacity: 0.5;
}
.list-content-box {
  margin-top: 0.8rem;
  width: 100%;
  color: #ffffff;
}
.list-content-box h2 {
  text-align: center;
  font-size: 0.48rem;
  font-family: Source Han Sans CN;
  font-weight: bold;
}
.list-content-box .h3 {
  color: #999999;
  font-size: 0.27rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  transform: scale(0.8);
  width: 120%;
  text-align: center;
  margin-left: -10%;
}
.list-content-box .p {
  font-size: 0.37rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 0.56rem;
}
.banner1 {
  height: 7.06rem;
  width: 100%;
  background: url("../../static/images/home-3.png");
  background-size: 100% 100%;
  position: relative;
}
.mask1 {
  position: absolute;
  top: 0;
  left: 0;
  background: RGBA(0, 0, 0, 0);
  height: 7.06rem;
  width: 100%;
}
.text1 {
  background: url("../../static/images/hoem-bg.png");
  background-size: 100% 100%;
  width: 6.6rem;
  height: 1.3rem;
  text-align: center;
  line-height: 1.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.65rem;
  margin-left: -3.3rem;
  font-size: 0.67rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
}
.banner2 {
  position: relative;
  height: 7.06rem;
  width: 100%;
  background: url("../../static/images/home-2.png");
  background-size: 100% 100%;
}
.text2 {
  background: url("../../static/images/hoem-bg.png");
  background-size: 100% 100%;
  width: 6.6rem;
  height: 1.3rem;
  text-align: center;
  line-height: 1.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.65rem;
  margin-left: -3.3rem;
  font-size: 0.67rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
}
.banner3 {
  position: relative;
  height: 7.06rem;
  width: 100%;
  background: url("../../static/images/home-1.png");
  background-size: 100% 100%;
}
.text3 {
  background: url("../../static/images/hoem-bg.png");
  background-size: 100% 100%;
  width: 6.6rem;
  height: 1.3rem;
  text-align: center;
  line-height: 1.3rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.65rem;
  margin-left: -3.3rem;
  font-size: 0.67rem;
  font-family: Source Han Sans CN;
  font-weight: 300;
  color: #ffffff;
}
.jump-img {
  width: 0.58rem;
  height: 0.72rem;
  position: absolute;
  bottom: 0.2rem;
  right: 0.2rem;
  display: flex;
  justify-content: flex-end;
}
.shou-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blocks-box {
  display: flex;
  width: 35rem;
  transition: transform 0.5s linear 0s;
}
.scroll-box1 {
  position: relative;
  padding-bottom: 1.57rem;
}
.progress-bar-box {
  height: 0.05rem;
  position: absolute;
  bottom: 0.8rem;
  left: 50%;
  margin-left: -2.1rem;
  .active-progress {
    position: absolute;
    width: 0.7rem;
    height: 0.05rem;
    left: 0;
    top: 0;
    background: #ffab0a;
  }
  .progress-bar {
    float: left;
    width: 0.7rem;
    height: 0.05rem;
    background: #cacaca;
  }
}
.activeBlock .list-num{
  color: #FFFFFF;
}
.activeBlock.list-box{
  background: url("../../static/images/fa_bg.png");
}
</style>
